
import axios from "axios";

const apiBase = "/api/";

let createPaypalOrder = function (payload) {
  let url = apiBase + "paypal/create/order";
  return axios
      .post(url, payload, {
        headers: {
          Authorization: localStorage.getItem("karaoke-user-token"),
        },
      })
      .then(function (res) {
        return res.data.id;
      })
      .catch(function (err) {
        let data = null;
        if (err.response) {
          data = err.response;
        } else {
          data = {
            message: err,
          };
        }
        return {
          error: true,
          data: data,
        };
      });
};

let capturePaypalOrder = function (payload) {
  let url = apiBase + "/paypal/capture/order";
  return axios
      .post(url, payload, {
        headers: {
          Authorization: localStorage.getItem("karaoke-user-token"),
        },
      })
      .then(function (res) {
        return res.data;
      })
      .catch(function (err) {
        let data = null;
        if (err.response) {
          data = err.response;
        } else {
          data = {
            message: err,
          };
        }
        return {
          error: true,
          data: data,
        };
      });
};

let captureAutoSubscription = function (payload) {
  let url = apiBase + "/paypal/auto-subscriptions/capture";
  return axios
      .post(url, payload, {
        headers: {
          Authorization: localStorage.getItem("karaoke-user-token"),
        },
      })
      .then(function (res) {
        return res.data;
      })
      .catch(function (err) {
        let data = null;
        if (err.response) {
          data = err.response;
        } else {
          data = {
            message: err,
          };
        }
        return {
          error: true,
          data: data,
        };
      });
};

let getPaypalKey = function (callback) {
  let url = apiBase + "paypal/key";
  axios
      .get(url, {
        headers: {
          Authorization: localStorage.getItem("karaoke-user-token"),
        },
      })
      .then(function (res) {
        callback({
          error: false,
          payload: res.data,
        });
      })
      .catch(function (err) {
        let data = null;
        if (err.response) {
          data = err.response;
        } else {
          data = {
            message: err,
          };
        }
        callback({
          error: true,
          payload: data,
        });
      });
};
let cancelAutopayment = function (callback) {
  let url = apiBase + "paypal/auto-subscriptions/cancel";
  axios
      .delete(url, {
        headers: {
          Authorization: localStorage.getItem("karaoke-user-token"),
        },
      })
      .then(function (res) {
        callback({
          error: false,
          payload: res.data,
        });
      })
      .catch(function (err) {
        let data = null;
        if (err.response) {
          data = err.response;
        } else {
          data = {
            message: err,
          };
        }
        callback({
          error: true,
          payload: data,
        });
      });
};

export {
  capturePaypalOrder,
  createPaypalOrder,
  captureAutoSubscription,
  getPaypalKey,
  cancelAutopayment
};
