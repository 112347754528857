<template>
  <div id="playlist" class="playlist-wrapper px-4 py-4">
    <div class="title">
      <h1>{{ playlistName[0].playlistName }}</h1>
    </div>
    <div class="search-and-add">
      <div v-if="isLogged" class="page-search-wrapper">
        <v-text-field
            ref="search"
            v-model="search"
            full-width
            hide-details
            :placeholder="$t('search-in-playlist')"
            single-line
            prepend-inner-icon="search"
            class="page-search"
        ></v-text-field>
      </div>
      <button @click="showAllSongs = true" class="action-button sm add-song">
        <plus-icon />
        Add song
      </button>
    </div>
    <v-row class="mx-0 my-0">
      <draggable
          v-model="playlistName[0].songs"
          :group="playlistName[0].songs"
          :disabled="search"
          class="draggable-list"
          item-class="draggable-item"
          ghost-class="ghost"
          @end="updateOrder"
          :options="{ handle: '.drag-handle' }"
      >
        <v-col v-for="(song, i) in filteredPlaylistSongs" :key="i" cols="12">
          <v-lazy>
            <v-hover v-slot:default="{ hover }">
              <v-card
                  :elevation="hover ? 16 : 2"
                  class="d-flex justify-space-between align-center pr-2"
              >
                <div @click="mobilePlaySong(song, i)" class="d-flex align-center flex-no-wrap song-block">
                  <div class="play-index">
                    <button v-on:click="selectSong(song, i)">
                      <play-icon />
                    </button>
                    <span>{{ i + 1 }}</span>
                  </div>
                  <v-avatar v-if="song.image" class="my-3 mx-1" size="30" tile>
                    <v-img :src="'/api/stream/' + song.image"></v-img>
                  </v-avatar>
                  <div class="desktop-song-name">
                    <v-card-subtitle>
                      <button
                          @click="selectArtist(song.artist)"
                          class="artists"
                      >
                        {{ song.artist }}
                      </button>
                      -
                      <button class="song-name" @click="selectSong(song, i)">
                        {{ song.songName }}
                      </button>
                    </v-card-subtitle>
                  </div>
                  <div class="mobile-song-name">
                    <h4>{{ song.songName }}</h4>
                    <span>{{ song.artist }}</span>
                  </div>
                </div>
                <button
                  @click="mobileSongOptions(song, i)"
                  class="mobile-song-options-button action-button action-button__white sm"
                >
                  <more-icon />
                </button>
                <button
                    @click="removeSong(playlistName[0]._id, song._id, i)"
                    class="remove-song action-button action-button__danger sm"
                >
                  <trash-icon />
                </button>
                <button class="drag-handle">
                  <sort-icon />
                </button>
              </v-card>
            </v-hover>
          </v-lazy>
        </v-col>
      </draggable>
    </v-row>
    <div
        class="mobile-song-options"
        :class="showMobileSongOptions ? 'show' : ''"
        v-click-outside="{
          handler: hideSongOptions,
          include,
        }"
    >
      <div class="mobile-song-options__info">
        <div class="d-flex align-center flex-no-wrap song-block">
          <v-avatar v-if="songInfo.image" class="ma-3" size="30" tile>
            <v-img :src="'/api/stream/' + songInfo.image"></v-img>
          </v-avatar>
          <div class="mobile-song-name">
            <h4>{{ songInfo.songName }}</h4>
            <span>{{ songInfo.artist }}</span>
          </div>
        </div>
      </div>
      <div class="mobile-song-options__action-wrapper">
        <div @click="removeSong(playlistName[0]._id, songInfo._id, selectedSongIndex)" class="mobile-song-options__action">
          <trash-icon />
          <span>Remove song from playlist</span>
        </div>
<!--        <div class="mobile-song-options__action">-->
<!--          <plus-icon />-->
<!--          <span>Add song to another playlist</span>-->
<!--        </div>-->
        <div @click="selectArtist(songInfo.artist)" class="mobile-song-options__action">
          <user-icon />
          <span>Go to artist</span>
        </div>
      </div>
    </div>
    <div class="add-songs-to-playlist" :class="showAllSongs ? 'show' : ''">
      <span class="add-songs-to-playlist__overlay" />
      <div class="add-songs-to-playlist__body">
        <div class="add-songs-to-playlist__search">
          <v-text-field
              ref="searchSongs"
              v-model="searchSongs"
              full-width
              hide-details
              placeholder="Search songs"
              single-line
              prepend-inner-icon="search"
              class="page-search"
          ></v-text-field>
        </div>
        <div class="add-songs-to-playlist__songs">
          <div
            v-for="song in filterAllSongs"
            :key="song._id"
            class="song"
            @click="addSong(playlistName[0]._id, song._id)"
          >
            <div class="song__info">
              <div class="text">
                <h4>{{ song.songName }}</h4>
                <span>{{ song.artist }}</span>
              </div>
            </div>
            <plus-icon />
          </div>
        </div>
        <div class="add-songs-to-playlist__close">
          <button @click="showAllSongs = false">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import {updateSongsOrder, getPlaylists, removeSongFromPlaylist, addSongToPlaylist} from "@/services/users";
import { getSongs } from "@/services/songs";
import {getSong, getSongUrl} from "@/services/songs";
import PlayIcon from "@/components/svg/PlayIcon.vue";
import TrashIcon from "@/components/svg/TrashIcon.vue";
import MoreIcon from "@/components/svg/MoreIcon.vue";
import PlusIcon from "@/components/svg/PlusIcon.vue";
import UserIcon from "@/components/svg/UserIcon.vue";
import SortIcon from "@/components/svg/SortIcon.vue";
// import EditIcon from "@/components/svg/EditIcon.vue";
// import CloseIcon from "@/components/svg/CloseIcon.vue";
// import CheckIcon from "@/components/svg/CheckIcon.vue";

export default {
  components: {
    SortIcon,
    UserIcon,
    MoreIcon,
    draggable,
    PlayIcon,
    TrashIcon,
    PlusIcon
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    playlistName() {
      return this.playlists.filter(
          (el) => el.playlistName === this.$route.params.name
      );
    },
    filteredPlaylistSongs() {
      const search = this.search.toLowerCase();

      if (!search) return this.playlistName[0].songs;

      return this.playlistName[0].songs.filter((song) => {
        const text = `${song.artist.toLocaleLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/ð|ď|đ/g, 'dj')} ${song.songName.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/ð|ď|đ/g, 'dj')}`;

        return text.indexOf(search) > -1;
      });
    },
    filterAllSongs() {
      const searchSongs = this.searchSongs.toLowerCase();

      if (!searchSongs) return this.songs;

      return this.songs.filter((song) => {
        const text = `${song.artist.toLocaleLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/ð|ď|đ/g, 'dj')} ${song.songName.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/ð|ď|đ/g, 'dj')}`;

        return text.indexOf(searchSongs) > -1;
      });
    },
  },
  name: "single_playlist",
  data() {
    return {
      playlists: [],
      songs: [],
      playlistNameEdit: false,
      showMobileSongOptions: false,
      songInfo: {},
      selectedSongIndex: null,
      search: "",
      searchSongs: "",
      showAllSongs: false
    };
  },
  methods: {
    fetchPlaylists() {
      const self = this;
      getPlaylists(function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.playlists = data.payload.playlists;
        }
      });
    },
    fetchSongs() {
      const self = this
      getSongs(function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.songs = data.payload;
        }
      });
    },
    selectSong: function (song, index) {
      let self = this;
      getSong(song._id, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
          self.$router.push("/");
        } else {
          self.song = data.payload;
          self.socialTitle = self.song.artist + "-" + self.song.songName;
          self.socialDescription = self.song.textContent;
          self.socialTags = self.song.tags.replace(/ /g, ",").replace(/#/g, "");
          getSongUrl(self.song._id, function (data) {
            if (data.error) {
              self.$emit("show-snackbar", data.payload.data.status, "error");
              self.$router.push("/");
            } else {
              self.$emit("song-selected", {
                songUrl: data.payload.url,
                song: self.song,
                limit: data.payload.limit,
                playlistId: index,
              });
            }
          });
        }
      });
    },
    mobilePlaySong (song, index) {
      if (window.innerWidth < 992) {
        this.selectSong(song, index)
      }
    },
    addSong: function (playlistId, songId) {
      let self = this;
      addSongToPlaylist(playlistId, songId, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.$emit("show-snackbar", "Added to playlist", "success");
          // self.songs.splice(index, 1);
        }
      });
      setTimeout(() => {
        this.fetchPlaylists()
      }, 50)
    },
    removeSong: function (playlistId, songId, index) {
      removeSongFromPlaylist(playlistId, songId, function (data) {
        if (data.error) {
          this.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          this.$emit("show-snackbar", "Added to playlist", "success");
          this.songs.splice(index, 1);
        }
      });
      this.showMobileSongOptions = false
      setTimeout(() => {
        this.fetchPlaylists()
      }, 50)
    },
    updateOrder: async function (event) {
      const self = this
      await updateSongsOrder(self.playlistName[0]._id, self.playlistName[0].songs[event.newIndex]._id, event.newIndex + 1, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.$emit("show-snackbar", "Order changed", "success");
        }
      });
      setTimeout(() => {
        self.fetchPlaylists()
      }, 50)
    },
    selectArtist: function (artist) {
      const artistName = artist.replace(/\s/g, '-')
      this.$router.push("/artist/" + artistName);
    },
    mobileSongOptions(song, i) {
      this.showMobileSongOptions = true
      this.songInfo = song
      this.selectedSongIndex = i
    },
    include() {
      return [document.querySelector(".show")];
    },
    hideSongOptions() {
      if (this.showMobileSongOptions) {
        this.showMobileSongOptions = false
      }
    }
  },
  beforeMount() {
    this.fetchPlaylists()
    this.fetchSongs()
  },
};
</script>
<style lang="scss" scoped>
.playlist-wrapper {
  width: 100%;
  max-width: 780px;
  margin: 0 auto;
}
.title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  float: left;

  h1 {
    color: var(--text);
    font-weight: 500;
  }
}

.search-and-add {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  /deep/ .page-search.v-input .v-input__control .v-input__slot {
    border-radius: 8px;
  }

  .add-song {
    min-width: 100px;
  }
}
/deep/ .page-search.v-input .v-input__control .v-input__slot {
  min-height: 40px !important;
}
.row {
  width: 100%;
}
/deep/ .centered-input input {
  text-align: center;
}
.v-sheet.v-card {
  box-shadow: none !important;
  border: 1px solid var(--border-color);
  background-color: var(--artist-background);
  cursor: pointer;
  border-radius: 8px !important;
  transition: 0.15s;

  &:hover {
    box-shadow: 1px 1px 5px 0px rgba(var(--box-shadow), 0.3) !important;
    @media (min-width: 992px) {
      .play-index {
        svg {
          display: block;
        }

        span {
          display: none;
        }
      }
    }
  }
}
.play-index {
  height: 54px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 28px;
    display: none;
  }
}

.artists:hover,
.song-name:hover {
  text-decoration: underline;
}

.draggable-list {
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .col {
    padding: 0;
    flex: none;
    .v-sheet.v-card {
      color: var(--text);
      cursor: default;
      position: relative;
      padding-right: 36px !important;
      overflow: hidden;

      .drag-handle {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 28px;
        cursor: grab !important;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--body-background);
        border-radius: 0;

        svg {
          height: 16px;
        }
      }
    }
  }

  .v-avatar .v-image {
    border-radius: 100%;
  }

  .song-block {
    width: 100%;
  }

  .mobile-song-name {
    display: flex;
    flex-direction: column;
    gap: 4px;

    h4 {
      margin: 0;
      line-height: 1;
      font-size: 14px;
      font-weight: 500;
      max-width: 20ch;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    span {
      font-size: 12px;
      line-height: 1;
    }
  }
}
.ghost {
  opacity: 0.5;
}

.mobile-song-options-button {
  width: 20px !important;
  svg {
    transform: rotate(90deg);
    width: 20px;
  }
}

.title-edit {
  width: 100%;
  height: 100dvh;
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  display: none;

  &.show {
    display: flex;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9997;
    background-color: rgba(#000, 0.2);
  }

  &__inner {
    background: var(--body-background-invert);
    border-radius: 10px;
    padding: 16px;
    display: flex;
    position: relative;
    z-index: 9998;
    width: 100%;
    max-width: 450px;
  }

  form {
    display: flex;
    width: 100% ;
  }
}

.mobile-song-options {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: var(--body-background-invert);
  box-shadow: 0 0 10px 0 rgba(var(--box-shadow), 0.2);
  padding: 20px 0;
  color: var(--text);
  transform: translateY(100%);
  transition: 0.2s;

  &.show {
    transform: translateY(0%);
  }

  &__info {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    gap: 12px;
    padding: 12px 20px;
    margin-bottom: 12px;

    svg {
      height: 20px;
    }

    span {
      line-height: 1;
    }
  }

  &__action {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    background-color: var(--body-background);
    border-radius: 8px;
    gap: 12px;
    padding: 12px 20px;
    transition: 0.15s;

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
      padding: 0 20px;
    }

    &:hover {
      background-color: var(--body-background-invert);
    }

    svg {
      height: 20px;
    }

    span {
      line-height: 1;
    }
  }
}

@media (min-width: 992px) {
  .mobile-song-name,
  .mobile-song-options-button,
  .mobile-song-options {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .remove-song {
    display: none;
  }
  .title {
    float: unset;
    width: 100%;
    justify-content: center;
  }
  h1 {
    font-size: 24px;
    text-align: center;
  }
  .desktop-song-name {
    display: none;
  }
}
.add-songs-to-playlist {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  display: none;
  padding: 20px;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  &.show {
    display: flex;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    z-index: 2;
    background-color: rgba(#000, 0.3);
  }

  &__body {
    position: relative;
    z-index: 4;
    background-color: var(--body-background-invert);
    border-radius: 12px;
    border: 1px solid var(--border-color);
    box-shadow: 0 0 10px 0 rgba(var(--box-shadow), 0.2);
    width: 100%;
    max-width: 450px;
  }

  &__search {
    padding: 8px;
    border-bottom: 1px solid var(--border-color);

    .page-search.v-input {
      margin-bottom: 0;
    }
    /deep/ .page-search.v-input .v-input__control .v-input__slot {
      border-radius: 12px;
    }
  }

  &__songs {
    padding: 8px;
    height: 288px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;

    .song {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--text);
      padding: 8px;
      border: 1px solid var(--border-color);
      border-radius: 12px;
      cursor: pointer;
      transition: 0.15s;

      &:hover {
        background-color: var(--body-background);
      }

      &__info {
        display: flex;
        align-items: center;
        gap: 4px;

        .v-avatar {
          border-radius: 100%;
          overflow: hidden;
          box-shadow: 0 0 10px 0 rgba(var(--box-shadow), 0.2);
        }

        .text {
          display: flex;
          flex-direction: column;
          gap: 4px;

          h4 {
            line-height: 1;
            margin: 0;
            font-weight: 500;
            font-size: 14px;
          }

          span {
            line-height: 1;
            font-size: 12px;
          }
        }
      }

      svg {
        height: 20px;
      }
    }
  }

  &__close {
    padding: 8px;
    border-top: 1px solid var(--border-color);

    button {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      background-color: var(--body-background-invert);
      color: var(--text);
      border: 1px solid var(--border-color);
      border-radius: 12px;
      transition: 0.15s;

      &:hover {
        background-color: var(--body-background);
      }
    }
  }
}
</style>
