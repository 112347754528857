<template>
  <div class="send-mails custom-input-wrapper">
    <h1>{{ $t("page.send_mails.name") }}</h1>
    <div>
      <v-text-field v-model="subject" label="Subject"></v-text-field>
    </div>
    <div class="see-email-body">
      <button @click="showEmailBody = true">{{ $t("see_email_body") }}</button>
    </div>
    <div class="add-mails">
      <v-text-field
        v-model="inputValue"
        @keyup.enter="addMails"
        label="Add mails"
      ></v-text-field>
      <button @click="addMails">
        <plus-icon />
      </button>
    </div>
    <div v-if="emails.length" class="mails-list">
      <div v-for="(email, index) in emails" :key="index" class="single-mail">
        <span>{{ email }}</span>
        <button @click="removeMail(index)">
          <close-icon></close-icon>
        </button>
      </div>
    </div>
    <div class="send-mails-action">
      <button @click="sendEmailsInBatches" :disabled="disabledSend">
        {{ $t("send_email") }}
      </button>
    </div>
    <div v-if="invalidEmails.length" class="mails-list invalid">
      <h5>{{ $t("invalid_mails") }}</h5>
      <div
        v-for="(email, index) in invalidEmails"
        :key="index"
        class="single-mail"
      >
        <span>{{ email }}</span>
      </div>
    </div>
    <v-dialog
      v-model="showEmailBody"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      content-class="news-dialog"
    >
      <v-card>
        <form class="custom-input-wrapper">
          <v-toolbar>
            <button
              type="button"
              @click="showEmailBody = false"
              class="action-button action-button__danger inverted"
            >
              <close-icon />
            </button>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <button
                v-if="editBody"
                type="button"
                class="button button-primary sm"
                @click="editBody = false"
              >
                {{ $t("actions.save") }}
              </button>
              <button
                v-if="!editBody"
                type="button"
                class="button button-primary sm"
                @click="editBody = true"
              >
                {{ $t("edit_email_body") }}
              </button>
            </v-toolbar-items>
          </v-toolbar>
          <v-container>
            <v-row>
              <div v-if="!editBody" v-html="body" class="body-block" />
              <v-col v-if="editBody">
                <v-textarea
                  rows="16"
                  v-model="body"
                  label="Content"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CloseIcon from "./svg/CloseIcon.vue";
import PlusIcon from "./svg/PlusIcon.vue";
import { sendMails } from "../services/users";

export default {
  components: {
    CloseIcon,
    PlusIcon,
  },
  data() {
    return {
      emails: [],
      invalidEmails: [],
      inputValue: "",
      subject: "",
      showEmailBody: false,
      editBody: false,
      body:
        "<pre>Postovani,​\n" +
        "\n \n" +
        " 12 Meseci ⬇ 40% (Jednokratna pretplata) pretplata za samo 249.99 eura:\n" +
        "\n" +
        "                     - 12 meseci karaoke zabave\n" +
        "                     - Mogućnost promene tonaliteta\n" +
        "                     - Izbor između raznovrsnih pesama\n" +
        "                     - Pristup novim pesmama u našoj biblioteci\n" +
        "                     - Playlista dostupna\n" +
        "                     - Video stranica\n" +
        "                     - Stranici jezici\n" +
        "                     - ForMac, Windows, iOS, Android, TV\n" +
        "\n" +
        "                   \n" +
        " 1 Mesec (1 Month 🗓️) pretplata za samo 34.99 eura:\n" +
        "\n" +
        "                    - Mesečna pretplata 30 dana\n" +
        "                    - Mogućnost promene tonaliteta\n" +
        "                    - Izbor između raznovrsnih pesama\n" +
        "                    - Pristup novim pesmama u našoj biblioteci\n" +
        "                    - Playlista dostupna\n" +
        "                    - Video stranica\n" +
        "                    - Strani jezici\n" +
        "                    - ForMac, Windows, iOS, Android, TV\n" +
        "\n" +
        "\n" +
        " Weekend Party (7 Days 7️⃣) pretplata za samo 19.99 eura:\n" +
        "\n" +
        "                    - Weekend pretplata 7 dana\n" +
        "                    - Mogućnost promene tonaliteta\n" +
        "                    - Izbor između raznovrsnih pesama\n" +
        "                    - Pristup novim pesmama u našoj biblioteci\n" +
        "                    - Playlista dostupna\n" +
        "                    - Video stranica\n" +
        "                    - Strani jezici\n" +
        "                    - ForMac, Windows, iOS, Android, TV\n" +
        "\n" +
        "\n" +
        "                💳  100% PAYMENT SECUR 👌 GOOD REIVIEWS 🥇 HIGH QUALITY 💽 100% ORIGINAL SONGS\n" +
        "\n" +
        "      \n" +
        "\n" +
        "          \n" +
        ">>> KLIKNI I ISKORISTI AKCIJU <<<\n" +
        "<a href=\"www.karaokestudiod.com\" target=\"_blank\">www.karaokestudiod.com</a>" +
        "\n" +
        "\n" +
        "Posetite naš sajt i pretplatite se već danas! Ponuda traje ograničeno vreme, stoga požurite i osigurajte svoju pretplatu po neverovatno povoljnoj ceni.\n" +
        "I da, Hvala vam što ste deo Karaoke Studio D porodice i radujemo se što ćemo vam pružiti nezaboravnu zabavu.\n" +
        "\n" +
        '</pre>',
    };
  },
  computed: {
    disabledSend() {
      return this.emails.length < 1 ?? !this.subject;
    },
  },
  methods: {
    addMails() {
      if (!this.inputValue.trim()) return;

      // Split input, trim spaces, and validate each email
      const emailList = this.inputValue.split(" ").map((email) => email.trim());

      // Email validation regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Separate valid and invalid emails
      const validEmails = emailList.filter((email) => emailRegex.test(email));
      const invalidEmailsList = emailList.filter(
        (email) => !emailRegex.test(email)
      );

      this.emails.push(
        ...validEmails.filter((email) => !this.emails.includes(email))
      );
      this.invalidEmails.push(
        ...invalidEmailsList.filter((email) => !this.emails.includes(email))
      );

      this.inputValue = "";
    },
    removeMail(index) {
      this.emails.splice(index, 1);
    },
    async sendEmailsInBatches() {
      const batchSize = 30;
      const allEmails = [...this.emails];
      const total = allEmails.length;

      for (let i = 0; i < total; i += batchSize) {
        const batch = allEmails.slice(i, i + batchSize);

        try {
          const data = await this.sendBatch(batch, this.subject, this.body);

          if (data.error) {
            this.$emit(
              "show-snackbar",
              data.payload?.data?.status || "Greška pri slanju",
              "error"
            );
          } else {
            this.$emit(
              "show-snackbar",
              `Batch ${i + 1}-${i + batch.length} uspešno poslat`,
              "success"
            );
          }
          await this.delay(1100);
        } catch (err) {
          this.$emit(
            "show-snackbar",
            "Došlo je do greške prilikom slanja emailova.",
            "error"
          );
        }
      }

      this.emails = [];
      this.inputValue = "";
      this.invalidEmails = [];
      this.$emit(
        "show-snackbar",
        "Svi emailovi su uspešno poslati!",
        "success"
      );
    },
    async sendBatch(emails, subject, body) {
      return new Promise((resolve) => {
        sendMails(emails, subject, body, function (data) {
          resolve(data);
        });
      });
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style scoped lang="scss">
.send-mails {
  max-width: 760px;
  margin: 0 auto;
  padding: 40px 16px;
}

h1 {
  text-align: center;
}

.see-email-body {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  button {
    width: 100%;
    line-height: 1;
    padding: 12px 32px;
    background-color: var(--body-background-invert);
    border: 1px solid var(--border-color);
    color: var(--text);
    border-radius: 8px;
    transition: 0.15s;

    &:hover {
      opacity: 0.6;
    }
  }
}

.mails-list {
  padding: 16px;
  border-radius: 12px;
  background-color: var(--body-background-invert);
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  &.invalid {
    margin-top: 48px;
    border: 1px solid #c41e3a;
    padding-top: 60px;
    position: relative;

    h5 {
      margin: 0;
      line-height: 1;
      font-size: 16px;
      color: #c41e3a;
      position: absolute;
      top: 12px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.single-mail {
  height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--body-background);
  border-radius: 4px;
  overflow: hidden;
  padding-left: 4px;

  span {
    line-height: 1;
    color: var(--text);
  }

  button {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #c41e3a;

    svg {
      height: 16px;
    }
  }
}

.add-mails {
  display: flex;
  align-items: center;
  gap: 12px;

  button {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: var(--body-background-invert);
    color: var(--text);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 24px;
    }
  }
}

.send-mails-action {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;

  button {
    width: 100%;
    max-width: 420px;
    line-height: 1;
    padding: 12px 32px;
    background-color: #c41e3a;
    color: white;
    border-radius: 8px;

    &:disabled {
      opacity: 0.6;
    }
  }
}

.body-block {
  width: 100%;
  padding: 20px;

  :deep(pre) {
    white-space: pre-wrap;
  }
}
</style>
