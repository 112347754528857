import axios from 'axios';

const apiBase = '/api/';

let createStripeCheckoutSession = function (priceId, callback) {
  let url = apiBase + 'stripe/create-checkout-session';
  axios
    .post(
      url,
      { priceId, hostName: location.origin },
      {
        headers: {
          Authorization: localStorage.getItem('karaoke-user-token'),
        },
      },
    )
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let verifyStripePayment = function (payload, callback) {
  let url = apiBase + 'stripe/verify-payment';
  axios
    .post(url, payload, {
      headers: {
        Authorization: localStorage.getItem('karaoke-user-token'),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let getSubscriptions = function (callback) {
  let url = apiBase + 'subscription';
  axios
    .get(url)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let createSubscription = function (props, callback) {
  let url = apiBase + 'subscription';
  axios
    .post(url, props, {
      headers: {
        Authorization: localStorage.getItem('karaoke-user-token'),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let editSubscription = function (value, props, callback) {
  let url = apiBase + 'subscription/' + value;
  axios
    .put(url, props, {
      headers: {
        Authorization: localStorage.getItem('karaoke-user-token'),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let deleteSubscription = function (value, callback) {
  let url = apiBase + 'subscription/' + value;
  axios
    .delete(url, {
      headers: {
        Authorization: localStorage.getItem('karaoke-user-token'),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let createCustomSubscription = function (props, callback) {
  let url = apiBase + '/subscription/add/custom';
  axios
    .post(url, props, {
      headers: {
        Authorization: localStorage.getItem('karaoke-user-token'),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

export {
  getSubscriptions,
  createSubscription,
  editSubscription,
  deleteSubscription,
  createCustomSubscription,
  createStripeCheckoutSession,
  verifyStripePayment,
};
