<template>
  <div id="Videos" class="videos" ref="Videos">
    <div v-if="isLogged" class="page-search-wrapper">
      <v-text-field
          ref="search"
          v-model="search"
          full-width
          hide-details
          :placeholder="$t('search-videos')"
          single-line
          prepend-inner-icon="search"
          class="page-search"
      ></v-text-field>
    </div>
    <div v-if="isLogged" class="videos__wrapper">
      <div class="left-block">
        <div
            v-if="isLogged"
            class="video-wrapper"
            :style="hideVideo ? 'display: none !important' : 'display: block'"
        >
          <video
              ref="video"
              :src="videoFile"
              preload="auto"
              style="width: 300px;"
              playsinline
              controls
              disablepictureinpicture
              controlslist="nodownload"
              @contextmenu.prevent
              @dblclick.prevent
          ></video>
          <button
              class="close-video"
              type="button"
              @click="stopVideo()"
          >
            <close-icon />
          </button>
        </div>
        <div v-if="hideVideo" class="videos__placeholder">
          <img class="logo" :src="DarkLogo" alt="logo" />
        </div>
        <div
            class="videos__playlist"
            :class="{'empty': playlist.length < 1}"
        >
          <span v-if="playlist.length < 1">{{ $t('playlist_empty') }}</span>
          <div v-else class="playlist-block" :class="{'video-active': !hideVideo}">
            <div v-for="(video, index) in playlist" :key="video._id" class="playlist-item">
              <div class="playlist-item--inner"  @click.prevent="videoToggle(video.id)">
                <div class="artist-image">
                  <img :src="`/api/stream/${video.image}`" alt="" />
                </div>
                <div class="song-info">
                  <p>{{ video.songName }}</p>
                  <span>{{ video.artist }}</span>
                </div>
              </div>
              <button class="remove-video" @click="removeVideo(index)">
                <trash-icon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="right-block">
        <div
            v-for="video in filteredVideos"
            :key="video._id"
            class="videos__single"
        >
          <div @click.prevent="videoToggle(video._id)" class="videos__single--inner">
            <div class="video-info">
              <h4>{{ video.songName }}</h4>
              <h6>{{ video.artist }}</h6>
            </div>
            <div class="video-image">
              <img :src="`/api/stream/${video.image}`" alt="" />
            </div>
          </div>
          <button class="add-to-playlist" :title="$t('add-to-playlist')" @click="addVideo(video._id, video.artist, video.songName, video.image)">
            <playlist-icon />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getVideos, getVideoUrl} from "@/services/videos";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import PlaylistIcon from "./svg/PlaylistIcon.vue";
import TrashIcon from "./svg/TrashIcon.vue";
import DarkLogo from '@/assets/logo.svg';

export default {
  name: "videos",
  components: {TrashIcon, PlaylistIcon, CloseIcon},
  metaInfo() {
    return {
      title: `Karaoke Studio D - Videos`,
      meta: [
        {
          name: "description",
          content:
              "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno!",
        },
        {
          name: "twitter:card",
          content: this.name,
        },
        {
          name: "twitter:site",
          content: "@matricestudiod",
        },
        {
          name: "twitter:title",
          content: `Karaoke Studio D - Videos`,
        },
        {
          name: "twitter:description",
          content:
              "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno!",
        },
        {
          name: "twitter:creator",
          content: "@matricestudiod",
        },
        {
          name: "twitter:image",
          content: `${window.location.origin}${this.imgPath}`,
        },
        {
          property: "og:title",
          content: `Karaoke Studio D - Videos`,
        },
        {
          property: "og:url",
          content: `${window.location.origin}/videos`,
        },
        {
          property: "og:image",
          content: `${window.location.origin}${this.imgPath}`,
        },
        {
          property: "og:description",
          content:
              "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno",
        },
        {property: "og:site_name", content: "Karaoke"},
        {property: "og:type", content: "website"},
        {name: "robots", content: "index,follow"},
      ],
    };
  },
  data() {
    return {
      DarkLogo,
      videos: [],
      search: "",
      video: undefined,
      videoFile: null,
      videoLoaded: false,
      videoPlaying: false,
      hideVideo: true,
      playlist: [],
      playlistVideo: {
        id: '',
        artist: '',
        songName: '',
        image: ''
      }
    };
  },
  watch: {
    videoPlaying(value) {
      if (value) {
        return this.video.play();
      }
      this.video.pause();
    },
    $route: function (value) {
      if (!value.path.includes('videos')) {
        this.closeVideo();
        this.stop();
        this.stopVideo()
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    shuffleVideos() {
      const self = this
      const randomElement = self.videos.sort(() => 0.5 - Math.random());
      return randomElement
    },
    filteredVideos() {
      const search = this.search.toLowerCase();

      if (!search) return this.shuffleVideos;

      return this.shuffleVideos.filter((video) => {
        const text = `${video.artist.toLocaleLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/ð|ď|đ/g, 'dj')} ${video.songName.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/ð|ď|đ/g, 'dj')}`;

        return text.indexOf(search) > -1;
      });
    },
  },
  methods: {
    createPlaylist() {
      localStorage.setItem("videoPlaylist", JSON.stringify(this.playlist));
    },
    setPlaylist() {
      if (!!localStorage.getItem("videoPlaylist")) {
        this.playlist = JSON.parse(localStorage.getItem("videoPlaylist"))
      }
    },
    addVideo(id, artist, name, image) {
      const self = this;
      if (!localStorage.getItem("videoPlaylist")) {
        self.createPlaylist()
      }
      self.playlist = JSON.parse(localStorage.getItem("videoPlaylist"));
      self.playlistVideo.id = id
      self.playlistVideo.artist = artist
      self.playlistVideo.songName = name
      self.playlistVideo.image = image
      self.playlist.push(self.playlistVideo)
      localStorage.setItem("videoPlaylist", JSON.stringify(self.playlist));
      self.$emit("show-snackbar", self.$t('added_to_playlist'), "success");
    },
    removeVideo(index) {
      this.playlist.splice(index, 1);
      localStorage.setItem("videoPlaylist", JSON.stringify(this.playlist));
    },
    closeVideo() {
      this.stop();
    },
    toggleFullScreen() {
      const video = this.$refs.video;
      if (video !== null) {
        if (document.fullscreenElement === null) {
          video.requestFullscreen();
        } else {
          document.exitFullscreen();
        }
      }
    },
    playVideo: function () {
      this.video.play();
    },
    // play: function () {
    //   this.playing = true;
    //   this.audio.play();
    // },
    // stop: function () {
    //   this.playing = false;
    // },
    stopVideo: function () {
      this.videoPlaying = false;
      this.video.currentTime = 0;
      this.hideVideo = true;
    },
    turnOffVideo() {
      const video = this.$refs.video;
      video.src = '';
      this.onLeavePIP();
      this.hideVideo = true;
    },
    videoToggle: function (videoId) {
      this.hideVideo = false;
      // window.scrollTo({
      //   top: 0,
      //   behavior: 'smooth',
      // });
      if (this.videoPlaying) {
        const self = this;
        // self.stopVideo();
        // self.turnOffVideo()
        getVideoUrl(videoId, function (data) {
          if (data.error) {
            self.$emit("show-snackbar", data.payload.data.status, "error");
          } else {
            self.video.src = data.payload.url;
            self.video.play();
          }
        });
      } else {
        const self = this;
        getVideoUrl(videoId, function (data) {
          if (data.error) {
            self.$emit("show-snackbar", data.payload.data.status, "error");
          } else {
            self.video.src = data.payload.url;
            self.video.play();
          }
        });
      }
    },
    preventContextMenu(event) {
      // Prevent right-click on the video
      event.preventDefault();
    },
    preventDoubleClick(event) {
      // Prevent default behavior on double click
      event.preventDefault();
    },
  },
  beforeMount() {
    let self = this;
    getVideos(function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
        self.$router.push("/");
      } else {
        self.videos = data.payload;
      }
    });
  },
  mounted() {
    this.audio = this.$el.querySelectorAll("audio")[0];
    this.video = this.$el.querySelectorAll("video")[0];
    this.video.disablePictureInPicture = true;
    this.$refs.video.disablePictureInPicture = true;
    this.$refs.video.addEventListener("contextmenu", this.preventContextMenu);
    this.$refs.video.addEventListener("dblclick", this.preventDoubleClick);
    this.video.addEventListener("pause", () => {
      this.videoPlaying = false;
    });
    this.video.addEventListener("play", () => {
      this.videoPlaying = true;
    });
    this.setPlaylist();
  },
  beforeDestroy() {
    this.$refs.video.removeEventListener("contextmenu", this.preventContextMenu);
    this.$refs.video.removeEventListener("dblclick", this.preventDoubleClick);
  },
};
</script>
