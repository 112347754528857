import axios from "axios";

const apiBase = "/api/";

let getNews = function (callback) {
  let url = apiBase + "news";
  axios
      .get(url)
      .then(function (res) {
        callback({
          error: false,
          payload: res.data,
        });
      })
      .catch(function (err) {
        let data = null;
        if (err.response) {
          data = err.response;
        } else {
          data = {
            message: err,
          };
        }
        callback({
          error: true,
          payload: data,
        });
      });
};

let createNews = function (props, callback) {
  let url = apiBase + "news";
  axios
      .post(url, props, {
        headers: {
          Authorization: localStorage.getItem("karaoke-user-token"),
        },
      })
      .then(function (res) {
        callback({
          error: false,
          payload: res.data,
        });
      })
      .catch(function (err) {
        let data = null;
        if (err.response) {
          data = err.response;
        } else {
          data = {
            message: err,
          };
        }
        callback({
          error: true,
          payload: data,
        });
      });
};

let editNews = function (value, props, callback) {
  let url = apiBase + "news/" + value;
  axios
      .put(url, props, {
        headers: {
          Authorization: localStorage.getItem("karaoke-user-token"),
        },
      })
      .then(function (res) {
        callback({
          error: false,
          payload: res.data,
        });
      })
      .catch(function (err) {
        let data = null;
        if (err.response) {
          data = err.response;
        } else {
          data = {
            message: err,
          };
        }
        callback({
          error: true,
          payload: data,
        });
      });
};

let deleteNews = function (value, callback) {
  let url = apiBase + "news/" + value;
  axios
      .delete(url, {
        headers: {
          Authorization: localStorage.getItem("karaoke-user-token"),
        },
      })
      .then(function (res) {
        callback({
          error: false,
          payload: res.data,
        });
      })
      .catch(function (err) {
        let data = null;
        if (err.response) {
          data = err.response;
        } else {
          data = {
            message: err,
          };
        }
        callback({
          error: true,
          payload: data,
        });
      });
};

export { getNews, createNews, editNews, deleteNews };