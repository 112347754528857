<template>
  <div id="AdminSubscription" class="admin-subscription">
    <v-container>
      <button
        v-if="isLogged && isAdmin"
        @click="
          dialogNew = true;
          editing = false;
        "
        class="action-button inverted mb-4 mt-5"
      >
        <plus-icon />
      </button>
      <v-row dense>
        <v-col v-for="(subscription, i) in subscriptions" :key="i" cols="12">
          <v-lazy>
            <v-hover v-slot:default="{ hover }">
              <v-card>
                <div class="d-flex flex-no-wrap justify-space-between align-center">
                  <div>
                    <v-card-title :class="[$vuetify.breakpoint.mdAndUp ? 'headline' : 'subtitle-1']"
                      >{{ subscription.name }}
                    </v-card-title>
                    <pre :class="[$vuetify.breakpoint.mdAndUp ? 'subtitle-1' : 'subtitle-2']">
                      {{ subscription.description }}
                    </pre>
                    <v-card-text class="price"> {{ subscription.price }}€ </v-card-text>
                    <v-card-text> {{ subscription.valueDays }} {{ $t('days') }} </v-card-text>
                  </div>
                  <v-avatar class="ma-3" size="40" tile>
                    <v-icon>mdi-currency-eur</v-icon>
                  </v-avatar>
                </div>
                <div v-if="isLogged && isAdmin">
                  <v-divider class="mx-4"></v-divider>
                  <v-card-actions>
                    <button
                      @click="editSubscriptionButton(subscription, i)"
                      class="action-button action-button__warning sm ml-2"
                    >
                      <edit-icon />
                    </button>
                    <button
                      @click="deleteSubscriptionButton(subscription, i)"
                      class="action-button action-button__danger sm ml-2"
                    >
                      <trash-icon />
                    </button>
                  </v-card-actions>
                </div>
              </v-card>
            </v-hover>
          </v-lazy>
        </v-col>
      </v-row>
      <v-dialog
        v-model="dialogNew"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        persistent
        content-class="news-dialog"
      >
        <v-card>
          <form class="custom-input-wrapper">
            <v-toolbar>
              <button
                type="button"
                @click="closeNewModal"
                class="action-button action-button__danger inverted"
              >
                <close-icon />
              </button>
              <v-toolbar-title v-if="editing"
                >{{ $t('edit_subscription') }}
                {{ newSubscription.name }}
              </v-toolbar-title>
              <v-toolbar-title v-else>{{ $t('create_new_subscription') }} </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <button
                  v-if="editing"
                  class="button button-primary sm"
                  @click="editSubscriptionSave"
                >
                  {{ $t('actions.save') }}
                </button>
                <button v-else class="button button-primary sm" @click="createSubscriptionButton">
                  {{ $t('actions.create') }}
                </button>
              </v-toolbar-items>
            </v-toolbar>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field v-model="newSubscription.name" label="Name"></v-text-field>
                  <v-text-field
                    v-model="newSubscription.price"
                    label="Price €"
                    type="number"
                  ></v-text-field>
                  <v-textarea
                    rows="8"
                    v-model="newSubscription.description"
                    label="Description"
                  ></v-textarea>
                  <v-text-field
                    v-model="newSubscription.valueDays"
                    label="Days"
                    type="number"
                  ></v-text-field>
                  <v-text-field
                    v-model="newSubscription.discount"
                    label="Discount %"
                    type="number"
                  ></v-text-field>
                  <v-text-field
                    v-model="newSubscription.plan_id"
                    label="Stripe PriceId"
                    type="string"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </form>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import {
  getSubscriptions,
  createSubscription,
  editSubscription,
  deleteSubscription,
} from '@/services/subscriptions';
import PlusIcon from '@/components/svg/PlusIcon.vue';
import EditIcon from '@/components/svg/EditIcon.vue';
import TrashIcon from '@/components/svg/TrashIcon.vue';
import CloseIcon from '@/components/svg/CloseIcon.vue';

export default {
  components: { CloseIcon, TrashIcon, EditIcon, PlusIcon },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    isAdmin() {
      return this.$store.state.user.role === 'legatus';
    },
  },
  name: 'admin_subscription',
  data() {
    return {
      subscriptions: [],
      dialogNew: false,
      editing: false,
      newSubscription: {
        name: '',
        price: 0,
        valueDays: 0,
        discount: 0,
        plan_id: '',
      },
      selectedSubscriptionIndex: null,
    };
  },
  methods: {
    closeNewModal: function () {
      this.newSubscription = {
        name: '',
        price: 0,
        valueDays: 0,
        discount: 0,
        plan_id: '',
      };
      this.dialogNew = false;
    },
    createSubscriptionButton: function () {
      let self = this;
      let props = {
        subscription: this.newSubscription,
      };
      createSubscription(props, function (data) {
        if (!data.error) {
          self.$emit('show-snackbar', data.payload.status, 'success');
          self.subscriptions.push(data.payload.subscription);
        } else {
          self.$emit('show-snackbar', data.payload.data.status, 'error');
        }
      });
    },
    deleteSubscriptionButton: function (subscription, index) {
      let self = this;
      deleteSubscription(subscription._id, function (data) {
        if (data.error) {
          self.$emit('show-snackbar', data.payload.data.status, 'error');
        } else {
          self.$emit('show-snackbar', 'Subscription Deleted', 'success');
          self.subscriptions.splice(index, 1);
        }
      });
    },
    editSubscriptionButton: function (item, index) {
      this.selectedSubscriptionIndex = index;
      this.newSubscription = item;
      this.dialogNew = true;
      this.editing = true;
    },
    editSubscriptionSave: function () {
      let self = this;
      let props = {
        subscription: this.newSubscription,
      };
      editSubscription(this.newSubscription._id, props, function (data) {
        if (data.error) {
          self.$emit('show-snackbar', data.payload.data.status, 'error');
        } else {
          self.$emit('show-snackbar', 'Subscription Modified', 'success');
        }
      });
    },
  },
  beforeMount() {
    let self = this;
    getSubscriptions(function (data) {
      if (data.error) {
        self.$emit('show-snackbar', data.payload.data.status, 'error');
      } else {
        self.subscriptions = data.payload.subscriptions;
      }
    });
  },
};
</script>
<style lang="scss" scoped></style>
