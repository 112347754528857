<template>
  <div id="home">
    <!--    <img class="christmas-decoration-1" :src="ChristmasDecoration" alt="">-->
    <!--    <img class="christmas-decoration-2" :src="ChristmasDecoration2" alt="">-->
    <div class="welcome-block">
      <div class="d-flex align-center justify-center">
        <div class="d-flex align-center">
          <div class="rotating-logo">
            <img class="logo" :src="DarkLogo" alt="logo" />
            <!--              <img v-if="theme === 'dark'" :src="LightLogo" alt="logo" />-->
          </div>
          <h1>Karaoke Studio D<sup>TM</sup></h1>
        </div>
      </div>
      <p>{{ $t('welcome_message') }}</p>
    </div>
    <div class="landing-artists-sliders">
      <div class="see-all justify-center">
        <router-link to="/artists" class="button button-primary sm"> View All Artists </router-link>
      </div>
      <div class="slider slider-1">
        <div class="slide-track">
          <div
            v-for="(artist, i) in randomArtistsFirstRow"
            :key="i + '-artist-' + '1-' + artists[artist].name"
            class="slide"
          >
            <img :src="artists[artist].src" alt="" />-->
          </div>
          <div
            v-for="(artist, i) in randomArtistsFirstRow"
            :key="i + '-artist-' + '2-' + artists[artist].name"
            class="slide"
          >
            <img :src="artists[artist].src" alt="" />-->
          </div>
        </div>
      </div>
      <div class="slider slider-2">
        <div class="slide-track">
          <div
            v-for="(artist, i) in randomArtistsSecondRow"
            :key="i + '-artist-' + '1-' + artists[artist].name"
            class="slide"
          >
            <img :src="artists[artist].src" alt="" />-->
          </div>
          <div
            v-for="(artist, i) in randomArtistsSecondRow"
            :key="i + '-artist-' + '2-' + artists[artist].name"
            class="slide"
          >
            <img :src="artists[artist].src" alt="" />-->
          </div>
        </div>
      </div>
      <div class="slider slider-3">
        <div class="slide-track">
          <div
            v-for="(artist, i) in randomArtistsThirdRow"
            :key="i + '-artist-' + '1-' + artists[artist].name"
            class="slide"
          >
            <img :src="artists[artist].src" alt="" />-->
          </div>
          <div
            v-for="(artist, i) in randomArtistsThirdRow"
            :key="i + '-artist-' + '2-' + artists[artist].name"
            class="slide"
          >
            <img :src="artists[artist].src" alt="" />-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getArtists } from '@/services/songs';
import DarkLogo from '@/assets/logo.svg';
import LightLogo from '@/assets/logo-white.svg';
import ChristmasDecoration from '@/assets/christmas-decoration.png';
import ChristmasDecoration2 from '@/assets/christmas-decoration-2.png';

export default {
  name: 'home',
  data() {
    return {
      theme: 'light',
      artists: [],
      randomArtistsFirstRow: [],
      randomArtistsSecondRow: [],
      randomArtistsThirdRow: [],
      loading: true,
      DarkLogo,
      LightLogo,
      ChristmasDecoration,
      ChristmasDecoration2,
    };
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
  },
  methods: {
    // selectArtist: function (artist) {
    //   this.$router.push("/artist/" + artist.name);
    // },
    tryGetArtists() {
      let self = this;
      getArtists(function (data) {
        if (data.error) {
          self.$emit('show-snackbar', data.payload.data.status, 'error');
          self.$router.push('/');
        } else {
          data.payload.forEach((item) => {
            const src = '/api/stream/' + item.replace(/ /g, '') + '.jpg';
            const artist = { name: item, src };
            self.artists.push(artist);
          });
          self.getRandomArtists();
        }
      });
    },
    getRandomArtists() {
      const self = this;
      const arr = Array.from({ length: 38 }, (_, i) => i + 1);
      self.randomArtistsFirstRow = arr.slice(0, 12);
      self.randomArtistsSecondRow = arr.slice(13, 24);
      self.randomArtistsThirdRow = arr.slice(25, 36);
    },
    getTheme() {
      if (localStorage.getItem('theme')) {
        this.theme = localStorage.getItem('theme');
      } else {
        localStorage.setItem('theme', 'light');
        this.theme = localStorage.getItem('theme');
      }
    },
  },
  mounted() {
    this.getTheme();
    this.tryGetArtists();
  },
};
</script>
<style lang="scss" scoped></style>
