<template>
  <div>
    <v-container fluid class="player" :style="hidePlayer ? 'display: none;' : 'display: block;'">
      <v-row class="player-block" v-if="!processing">
        <button @click="minimizePlayer" class="minimize-button">
          <minimize-icon />
        </button>
        <div class="avatar-block">
          <v-avatar size="70">
            <v-img :src="'/api/stream/' + this.song.image"></v-img>
          </v-avatar>
          <div class="avatar-block__info">
            <h4>{{ this.song.songName }}</h4>
            <span>{{ this.song.artist }}</span>
          </div>
        </div>
        <div class="player-controls">
          <div class="top-controls">
            <button @click.prevent="playing = !playing">
              <play-icon v-if="!playing" />
              <pause-icon v-else />
            </button>
          </div>
          <div class="progress">
            <v-progress-linear
              v-on:click="seek"
              :value="this.percentComplete"
              color="black"
              style="cursor: pointer"
            ></v-progress-linear>
          </div>
          <div class="bottom-controls">
            <span>
              {{ this.currentSeconds | convertTimeHHMMSS }}
            </span>
            <button v-on:click.prevent="stop">
              <stop-icon />
            </button>
            <span>
              {{ this.durationSeconds | convertTimeHHMMSS }}
            </span>
          </div>
        </div>
        <div class="right-controls">
          <button
            @click="showText = !showText"
            class="text-button"
            :class="showText ? 'active' : ''"
          >
            <text-icon />
          </button>
          <v-menu top offset-y open-on-hover class="sound-settings">
            <template v-slot:activator="{ on }">
              <button v-on="on" v-on:click.prevent="mute" class="sound-button">
                <loud-icon v-if="!muted" />
                <muted-button v-else />
              </button>
            </template>
            <v-slider
              vertical
              v-model="media"
              min="0"
              max="100"
              v-model.lazy.number="volume"
              class="overflow-hidden"
            ></v-slider>
          </v-menu>
          <div class="custom-input-wrapper">
            <v-select
              v-model="modifications.pitch"
              :items="items"
              menu-props="auto"
              label="Pitch"
              :disabled="!isLogged"
              v-on:change="modifySong"
            ></v-select>
          </div>
        </div>
      </v-row>
      <v-row align="center" justify="center" v-else>
        <v-col class="subtitle-1 text-center" cols="12">
          {{ $t('request_is_processing') }}
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="red darken-3"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          {{ videoFile }}
        </v-col>
      </v-row>
      <audio ref="audiofile" :src="file" style="display: none"></audio>
    </v-container>
    <div class="text-wrapper" :class="showText ? 'show' : ''">
      <div id="Song" class="song">
        <v-card>
          <v-card-title class="justify-center">
            {{ song.artist }} - {{ song.songName }}
          </v-card-title>
          <v-card-text>
            <pre class="text-center">{{ song.textContent }}</pre>
          </v-card-text>
        </v-card>
      </div>
      <button @click="showText = false" class="hide-text">
        <close-icon />
      </button>
    </div>
  </div>
</template>

<script>
import { getSongUrl } from '@/services/songs';
import CloseIcon from '@/components/svg/CloseIcon.vue';
import StopIcon from '@/components/svg/StopIcon.vue';
import PlayIcon from '@/components/svg/PlayIcon.vue';
import PauseIcon from '@/components/svg/PauseIcon.vue';
import TextIcon from '@/components/svg/TextIcon.vue';
import LoudIcon from '@/components/svg/LoudIcon.vue';
import MutedButton from '@/components/svg/MutedButton.vue';
import MinimizeIcon from '@/components/svg/MinimizeIcon.vue';

export default {
  components: {
    MinimizeIcon,
    MutedButton,
    LoudIcon,
    TextIcon,
    PauseIcon,
    PlayIcon,
    StopIcon,
    CloseIcon,
  },
  props: {
    file: {
      type: String,
      default: null,
    },
    song: {},
    autoPlay: {
      type: Boolean,
      default: false,
    },
    limit: {
      default: false,
    },
    playlistId: {
      default: null,
    },
  },
  data: () => ({
    audio: undefined,
    video: undefined,
    videoFile: null,
    currentSeconds: 0,
    durationSeconds: 0,
    bufferedSeconds: 0,
    loaded: false,
    videoLoaded: false,
    playing: false,
    videoPlaying: false,
    previousVolume: 35,
    showVolume: false,
    volume: 100,
    modifications: {
      pitch: '0',
    },
    items: [
      { text: '-6', value: '-600' },
      { text: '-5', value: '-500' },
      { text: '-4', value: '-400' },
      { text: '-3', value: '-300' },
      { text: '-2', value: '-200' },
      { text: '-1', value: '-100' },
      { text: '0', value: '0' },
      { text: '1', value: '100' },
      { text: '2', value: '200' },
      { text: '3', value: '300' },
      { text: '4', value: '400' },
      { text: '5', value: '500' },
      { text: '6', value: '600' },
    ],
    processing: false,
    showVideo: true,
    hideVideo: true,
    hidePlayer: false,
    showText: false,
  }),
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    isAdmin() {
      return this.$store.state.user.role === 'legatus';
    },
    showMobile() {
      return !!this.$vuetify.breakpoint.smAndUp;
    },
    muted() {
      return this.volume / 100 === 0;
    },
    percentComplete() {
      return parseInt((this.currentSeconds / this.durationSeconds) * 100);
    },
    percentBuffered() {
      return parseInt((this.bufferedSeconds / this.durationSeconds) * 100);
    },
  },
  filters: {
    convertTimeHHMMSS(val) {
      let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

      return hhmmss.indexOf('00:') === 0 ? hhmmss.substr(3) : hhmmss;
    },
  },
  watch: {
    playing(value) {
      if (value) {
        return this.audio.play();
      }
      this.audio.pause();
    },
    videoPlaying(value) {
      if (value) {
        return this.video.play();
      }
      this.video.pause();
    },
    currentSeconds(value) {
      if (this.limit) {
        if (this.currentSeconds > this.limit) {
          this.stop();
        }
      }
      if (this.currentSeconds >= this.durationSeconds) {
        this.$emit('song-finished-event', {
          song: this.song,
          index: this.playlistId,
        });
      }
    },
    volume(value) {
      this.audio.volume = this.volume / 100;
    },
    $route: function (value) {
      if (value.path.includes('videos')) {
        this.stop();
        this.hideVideo = true;
        this.file = null;
        this.hidePlayer = true;
      } else {
        this.hidePlayer = false;
      }
    },
  },
  methods: {
    minimizePlayer() {
      this.$emit('hide-player', {});
      this.showText = false;
    },
    load: function () {
      if (this.audio.readyState >= 2) {
        this.loaded = true;
        if (this.limit) {
          this.durationSeconds = parseInt(this.limit);
        } else {
          const durationNew = parseInt(this.audio.duration);
          if (durationNew > 0) {
            this.durationSeconds = durationNew;
          }
        }
        this.$emit('song-loaded', {});
      } else {
        throw new Error('Failed to load sound file.');
      }
    },
    play: function () {
      this.playing = true;
      this.audio.play();
    },
    modifySong: function () {
      let self = this;
      this.processing = true;
      this.stop();
      if (this.pitch !== '0' && this.modifications.pitch === '0') {
        getSongUrl(self.song._id, function (data) {
          if (data.error) {
            self.$emit('show-snackbar', data.payload.data.status, 'error');
            self.processing = false;
          } else {
            self.processing = false;
            self.audio.src = data.payload.url;
          }
        });
      } else if (this.pitch !== '0') {
        const fileNameEncoded = encodeURIComponent(this.song.fileName);
        self.audio.src = `/api/audio?fileName=${fileNameEncoded}&pitch=${this.modifications.pitch}`;
        self.processing = false;
      }
    },
    mute: function () {
      if (this.muted) {
        return (this.volume = this.previousVolume);
      }

      this.previousVolume = this.volume;
      this.volume = 0;
    },
    seek: function (e) {
      if (!this.loaded) return;

      const el = e.currentTarget.getBoundingClientRect();
      const seekPos = (e.clientX - el.left) / el.width;

      if (this.limit) {
        this.audio.currentTime = parseInt(this.limit * seekPos);
      } else {
        this.audio.currentTime = parseInt(this.durationSeconds * seekPos);
      }
    },
    stop: function () {
      this.playing = false;
      this.audio.currentTime = 0;
    },
    update: function (e) {
      this.currentSeconds = parseInt(this.audio.currentTime);
      this.bufferedSeconds =
        this.playing && this.audio.currentTime > 0 ? this.audio.buffered.end(0) : 0;
    },
  },
  mounted() {
    this.audio = this.$el.querySelectorAll('audio')[0];
    this.audio.addEventListener('timeupdate', this.update);
    this.audio.addEventListener('loadeddata', this.load);
    this.audio.addEventListener('pause', () => {
      this.playing = false;
    });
    this.audio.addEventListener('play', () => {
      this.playing = true;
    });
  },
};
</script>
<style lang="scss" scoped>
.player-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 20px;
  }

  button {
    color: var(--text);

    svg {
      height: 28px;
      width: 28px;
    }
  }

  .avatar-block {
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: 991px) {
      display: none;
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: var(--text);

      h4 {
        font-size: 16px;
        margin: 0;
        line-height: 1;
      }

      span {
        font-size: 14px;
        line-height: 1;
      }
    }
  }

  .player-controls {
    position: relative;
    z-index: 99;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;

    .top-controls {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .progress {
      border-radius: 8px;
      overflow: hidden;
    }

    .bottom-controls {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-weight: 500;
        color: var(--text);
      }
    }
  }

  .right-controls {
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
    gap: 16px;

    .custom-input-wrapper {
      width: 70px;
    }

    button {
      height: 28px;
    }

    .text-button.active {
      color: #d60017;
    }
  }
}

.text-wrapper {
  position: fixed;
  left: 0;
  bottom: 138px;
  width: 100%;
  background-color: var(--body-background);
  z-index: 8;
  height: calc(100dvh - 138px);
  overflow-y: auto;
  overflow-x: hidden;
  transform: translateY(200%);
  transition: 0.3s;

  @media (max-width: 991px) {
    height: calc(100dvh - 64px);
    bottom: 64px;
    padding-top: 20px;
  }

  &.show {
    transform: translateY(0%);
  }

  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }

  .hide-text {
    position: fixed;
    top: 20px;
    right: 20px;
    color: var(--icons-color);

    svg {
      height: 32px;
      width: 32px;
    }

    @media (max-width: 991px) {
      top: 12px;
      right: 12px;
    }
  }
}

.v-text-field__details {
  display: none !important;
}

.minimize-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--body-background);
  border-bottom-left-radius: 8px;
  color: #d60017 !important;
  z-index: 100;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

@media (max-width: 991px) {
  .container {
    padding: 16px 12px;
  }

  .sound-button,
  .sound-settings,
  /deep/ .v-text-field__details {
    display: none;
  }

  .player-block .player-controls {
    gap: 8px;
    position: static;
    flex-direction: row;
    width: auto;

    button {
      height: 28px;
    }

    .progress {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-radius: 0 !important;
    }

    .bottom-controls {
      span {
        display: none;
      }
    }
  }

  .player-block {
    gap: 12px;
    flex-direction: row;
    padding-right: 40px;
  }

  .right-controls {
    /deep/ .v-select__slot {
      label {
        position: absolute !important;
        top: -16px;
        left: 4px !important;
      }
    }

    /deep/ .v-input__slot {
      input {
        height: 32px;
      }
    }
  }

  .minimize-button {
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    width: auto;
    height: auto;

    svg {
      width: 28px !important;
      height: 28px !important;
    }
  }

  /deep/ .custom-input-wrapper .v-input__slot {
    margin-bottom: 0;
  }
}
</style>
