<template>
  <v-dialog v-model="dialog" width="50%" persistent>
    <v-card>
      <v-card-title>{{ dialogContent.title }}</v-card-title>
      <v-card-text>
        {{ dialogContent.text }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dialogAction()">
          {{ dialogContent.button }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { verifyStripePayment } from '../services/subscriptions';

export default {
  data() {
    return {
      dialog: false,
      dialogContent: {
        title: '',
        text: '',
        action: 'success',
        button: 'Ok',
      },
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    dialogAction() {
      if (this.dialogContent.action === 'error') {
        this.dialog = false;
      } else {
        this.$router.push('/');
      }
    },
  },
  mounted() {
    let self = this;

    const queryParams = self.$route.query;

    const sessionId = queryParams.session_id;
    const subscriptionId = queryParams.subscription_id;
    const userId = queryParams.user_id;

    if (sessionId) {
      const payload = { sessionId, subscriptionId, userId };
      verifyStripePayment(payload, function (response) {
        if (response.payload.error_code === 0) {
          self.$store.state.isLogged = true;
          self.$store.state.user = response.payload.user;
          localStorage.setItem('karaoke-user-token', response.payload.token);

          self.dialogContent.title = 'Uspešna pretplata';
          self.dialogContent.text =
            'Hvala Vam što se pretplatili i podržavate Karaoke Studio D, ukoliko imate predloge za poboljšanje možete nas kontaktirati putem email-a na studiodsrb@hotmail.com';
          self.dialogContent.action = 'success';
          self.dialogContent.button = 'Ok';
          self.$emit('finished-payment');
          self.openDialog();
        } else {
          self.dialogContent.title = 'Greška prilikom obrade';
          self.dialogContent.text =
            'Došlo je do greške prilikom obrade uplate, proverite da li Vam je preplata naplaćena, ukoliko jesu kontaktirajte nas putem email-a na studiodsrb@hotmail.com zbog provere, u suprotnom pokušajte ponovo';
          self.dialogContent.action = 'error';
          self.dialogContent.button = 'Probaj ponovo';
          self.$emit('finished-payment');
          self.openDialog();
        }
      });
    }
  },
};
</script>
