<template>
  <div id="BuySubscription" class="buy-subscription">
    <v-item-group v-model="selectedSubscription" mandatory>
      <v-container>
        <v-row>
          <v-col v-for="(subscription, i) in subscriptions" :key="i" cols="12">
            <v-item :value="subscription" v-slot="{ active, toggle }">
              <v-card
                :class="active ? 'active' : ''"
                :elevation="active ? 16 : 2"
                @click="subscribe(subscription.plan_id)"
              >
                <div class="d-flex flex-no-wrap justify-space-between">
                  <div>
                    <v-card-title
                      :class="[$vuetify.breakpoint.mdAndUp ? 'subtitle-1' : 'subtitle-2']"
                      >{{ subscription.name }}
                    </v-card-title>
                    <v-card-text
                      :class="[$vuetify.breakpoint.mdAndUp ? 'subtitle-1' : 'subtitle-2']"
                      v-html="subscription.description"
                    ></v-card-text>
                    <v-card-title
                      class="price"
                      :class="[$vuetify.breakpoint.mdAndUp ? 'subtitle-1' : 'subtitle-2']"
                    >
                      {{ subscription.price }}€
                    </v-card-title>
                    <v-card-title
                      :class="[$vuetify.breakpoint.mdAndUp ? 'subtitle-1' : 'subtitle-2']"
                    >
                      {{ subscription.valueDays }}
                      {{ $t('days') }}
                    </v-card-title>
                  </div>
                  <v-avatar class="ma-5" size="150" tile>
                    <v-icon large>mdi-currency-eur</v-icon>
                  </v-avatar>
                </div>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
    <v-overlay :value="paymentProcessing" z-index="999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { getSubscriptions, createStripeCheckoutSession } from '@/services/subscriptions';

export default {
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    isAdmin() {
      return this.$store.state.user.role === 'legatus';
    },
  },
  name: 'buy_subscription',
  data() {
    return {
      subscriptions: [],
      selectedSubscription: {
        price: 0,
        name: '',
        valueDays: 0,
        plan_id: '',
      },
      isAutoPayment: true,
      selectedSubscriptionIndex: [],
      paymentProcessing: false,
    };
  },
  methods: {
    subscribe(id) {
      createStripeCheckoutSession(id, function (data) {
        if (data.error) {
          self.$emit('show-snackbar', data.payload.data.status, 'error');
          self.$router.push('/');
        } else {
          window.location.assign(data.payload.sessionUrl);
        }
      });
    },
  },
  beforeMount() {
    let self = this;
    getSubscriptions(function (data) {
      if (data.error) {
        self.$emit('show-snackbar', data.payload.data.status, 'error');
      } else {
        self.subscriptions = data.payload.subscriptions;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.v-application .light-blue.lighten-5 {
  border-left: 5px solid;
  border-color: #009cde !important;
}
</style>
